import React, { useState } from "react";
import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { HttpRepository } from "./repositories/httpRepository";
import { TotemLogin } from "./models/totemLogin";
import { TotemConfigResult } from "./models/totemConfigResult";
import enviroment from "./models/Enviroment";
import { TotemConfig } from "./models/totemConfig";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  version: {
    position: "absolute",
    left: 0,
    bottom: 0
  }
});

declare const CefSharp: any;
declare const browserService: any;

function App() {
  const apiUrlBase = enviroment.apiUrl;
  const appUrl = enviroment.appUrl;

  const httpRepository = HttpRepository(apiUrlBase);

  const [messages, setMessages] = useState<
    Array<{ comando: number; message: string; severity: string }>
  >([]);

  const [imei, setImei] = useState<string>();
  const [cefSharpVersion, setCefSharpVersion] = useState<string>();
  const [totemConfig, setTotemConfig] = useState<TotemConfig>();
  const [jsonConfig, setJsonCOnfig] = useState<string>();

  (window as any).showMessageFromClient = (
    message: string,
    comando: number,
    severity: string
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { comando: comando, message: message, severity: severity },
    ]);
  };

  (window as any).setCefSharpVersion = async (cefSharpVersion: string) => {
    setCefSharpVersion(cefSharpVersion);
  }

  (window as any).setConfigTotem = async (macAddress: string) => {
    setImei(macAddress);
    try {
      const totemLogin = await httpRepository.postAsync<any, TotemLogin>(
        "api/UsuarioMeepPOS/LoginAutomaticoPOS",
        {
          IMEI: macAddress,
        }
      );

      const totemConfigResult = await httpRepository.postAsync<
        any,
        TotemConfigResult
      >(
        "api/UsuarioMeepPOS/AtualizarDadosEquipamento",
        {
          IMEI: macAddress,
          UsuarioMeepPOSId: totemLogin.usuarioMeepPOSId,
        },
        totemLogin.token
      );
      console.log(totemConfigResult);
      const conf: TotemConfig = {
        PrintQRCodeTicket: totemConfigResult.imprimirQRCodeFicha,
        CNPJ: totemConfigResult.CNPJ,
        PrintType: totemConfigResult.tipoImpressao,
        TickectName: totemConfigResult.localNome,
        TicketNote: totemConfigResult.observacaoFicha,
        UserAgent: totemConfigResult.usuarioMeepPOSApelido,
        EquipamentId: totemConfigResult.usuarioMeepPOSId,
        UrlApp: appUrl,
        Token: totemLogin.token,
        StoreCode: totemConfigResult.codigoUnico,
        AddressLocalServer: totemConfigResult.enderecoServidorLocal,
        SoftExpressCnpj: totemConfigResult.softExpressCnpj,
        SoftExpressStoreCode: totemConfigResult.softExpressStoreCode,
        AcquirerType: totemConfigResult.acquirerType
      };
      setConfig(conf);
    } catch (ex) {
      console.log("ex:", ex);
      const conf: TotemConfig = {
        PrintQRCodeTicket: false,
        PrintType: 0,
      };
      setConfig(conf);
    }
  };

  const setConfig = (conf: TotemConfig) => {
    setTotemConfig(conf);
    const strConfig = JSON.stringify(conf);
    console.log(strConfig);
    setJsonCOnfig(strConfig);
    (window as any).totemConfig = strConfig;
  };

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const copyToClipBoard = async () => {
    await CefSharp.BindObjectAsync("browserService");
    browserService.setClipBoardTextValue(jsonConfig ?? "");
  };

  const classes = useStyles();

  return (
    <div className="App">
      <div id="totem-data">
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              IMEI - {imei}
            </Typography>
            {cefSharpVersion && 
              <Typography
                className={classes.version}
                color="textSecondary"
                gutterBottom
              >
                {cefSharpVersion}
              </Typography>
            }
            <Typography variant="h5" component="h2">
              {totemConfig?.TickectName} - {totemConfig?.CNPJ}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Equipamento - {totemConfig?.EquipamentId}
            </Typography>
            <Typography variant="body2" component="p">
              Usuário Meep - {totemConfig?.UserAgent}
              <br />
              Código único - {totemConfig?.StoreCode}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              disabled={!jsonConfig}
              onClick={copyToClipBoard}
            >
              Copiar Configurações
            </Button>
          </CardActions>
        </Card>
      </div>
      <div id="messages">
        {messages.map((men) => {
          return (
            <Alert
              style={{ margin: "25px 80px 0px 80px" }}
              severity={men.severity}
            >
              {men.message}
            </Alert>
          );
        })}
      </div>
    </div>
  );
}

export default App;
